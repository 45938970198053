import * as React from 'react';
import {Helmet} from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@sass/main.sass';

import appleTouchIcon from '@images/favicon/apple-touch-icon.png';
import favicon32x32 from '@images/favicon/favicon-32x32.png';
import favicon16x16 from '@images/favicon/favicon-16x16.png';
import ogImage from '@images/meta/atakama-og-image.jpg';

const PrivacyPolicyPopup = React.lazy(() => import('@components/common/privacy-policy-popup'));

export default function Layout({children}) {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      <Helmet>
        <html lang="pl" />
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff"></meta>
        <meta property="og:image" content={ogImage} />
      </Helmet>
      {children}
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <PrivacyPolicyPopup />
        </React.Suspense>
      )}
    </>
  );
}
