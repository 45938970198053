import * as React from 'react';
import {Link} from 'gatsby';

import atakamaStudioLogo from '@images/atakama-studio-logo.svg';

import {Container, Row, Col} from 'react-bootstrap';
import {Instagram, Facebook} from 'react-bootstrap-icons';

import {useLocation} from '@reach/router';

const Navigation = props => {
  const location = useLocation();

  return (
    <nav>
      <Container>
        <Row>
          <Col className="logo-col">
            <Link to="/">
              <img src={atakamaStudioLogo} alt="Atakama Studio - pracownia architektury w Katowicach - logo" />
            </Link>
          </Col>
          <Col className="rest-col">
            {/* <a onClick={() => props.scrollHandler('projekty')}>Projekty</a>
            <a onClick={() => props.scrollHandler('kontakt')}>Kontakt</a>
            <a href="https://www.facebook.com/atakama.studio.arch" className="rest-col__social-icon">
              <Facebook />
            </a>
            <a href="https://www.instagram.com/atakama.studio.arch" className="rest-col__social-icon">
              <Instagram />
            </a> */}
            {location.pathname !== `/kontakt` && (
              <Link to={`/kontakt`} className="rest-col__contact">
                Kontakt
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </nav>
  );
};

export default Navigation;
