import * as React from 'react';

import {Container, Row, Col} from 'react-bootstrap';

const scrollTop = () => {
  window.scrollTo({top: 0, behavior: 'smooth'});
};

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col className="footer__container">
            <span>&copy; 2022 Atakama Studio</span>
            <a className="white" onClick={scrollTop} aria-hidden="true">
              Do góry &uarr;
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
